import React, {useEffect} from "react";
import Sidebar from "../../components/sidebar";
import Stats from "../../components/stats";
import EquipmentListTable from "../../components/equipmentListTable";
import MaintanceIdTable from "../../components/maintanceIdTable";
import LineChart from "../../components/chart";
import EnergyDataTable from "../../components/energyDataTable";
import AINonConsumption from "../../components/ai-non-consumption";
import Drawer from "../../components/drawer";
import EnergyGraph from "../../components/energyGraph";

export default function EnergyConsumption({
                                              setIsMenu,
                                              isMenu,
                                              setShow,
                                              setIsSetting,
                                              isAIMood,
                                              setIsAIMood,
                                          }) {
    useEffect(() => {
        if (isAIMood) {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [isAIMood]);
    const url = "http://13.212.150.127:13333/api/Ext_AdminData/GetData";
    const token =
        "1B195C7E6543D3DDF8832388CCD2917B7DE98EB736984E8698248B4EE3742646";

    const headers = {
        "Content-Type": "application/json",
        token: token,
    };

    const fetchData = async (id) => {
        const requestData = {
            id: id,
            stime: "2024-05-01 00:00:00",
            etime: "2024-05-31 00:00:00",
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok " + response.statusText);
            }

            const data = await response.json();
            return data; // Assuming the data contains the value you want to sum
        } catch (error) {
            return 0; // Return 0 in case of error to ensure it doesn't affect the sum
        }
    };
    useEffect(() => {
        let response = fetchData("29762497653219328");
        console.log("API response", response);
    }, []);
    return (
        <div
            className="w-[100%] flex bg-no-repeat  bg-cover"
            style={{...(isAIMood && {backgroundImage: "url(/Carbon.png)"})}}
        >
            <Sidebar
                setIsMenu={setIsMenu}
                isMenu={isMenu}
                setShow={setShow}
                setIsSetting={setIsSetting}
            />
            <div className="lg:p-[24px] p-[12px] h-[100vh] w-[100%] overflow-scroll my-scrollar">
                <Drawer/>
                <div className="flex items-center justify-between  xl:mt-0 mt-3">
                    <div>
                        <p className="font-[500] lg:text-[34px] text-[16px]">
                            Energy Consumption
                        </p>
                        <p className="text-[#9BB9A9] lg:text-[13px] text-[8px] font-medium">
                            Overview of building energy usage and trends
                        </p>
                    </div>
                    <div className="flex items-center justify-end">
                        <div className="lg:flex items-center justify-center mr-4 hidden">
                            <small className="mr-2">Partnered by</small>
                                          <img src="/tuv-rheinland-logo.png" alt='TUV' className={`h-[36px] px-2 py-1 rounded bg-[#ffffff]`}/>
                        </div>
                        <div class="gradient-border sm:py-[12px] py-[8px] sm:px-[16px] px-[10px] ">
                            <div className="flex items-center">
                                <p className="text-[#D8D8D8] font-[600] sm:text-[13px] text-[10px]">
                                    AI Savings
                                </p>
                                <label class="inline-flex items-center cursor-pointer ml-2">
                                    <input
                                        type="checkbox"
                                        checked={isAIMood}
                                        onChange={(event) => setIsAIMood(event.target.checked)}
                                        class="sr-only peer focus-visible:outline-none"
                                    />
                                    <div
                                        class="relative w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:bg-[#336633]  after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gradient-to-r peer-checked:from-[#99CC33] peer-checked:to-[#00C5A2]"></div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12  mt-[48px] gap-4">
                    <div className="grid lg:col-span-8 col-span-12">
                        <div
                            className="p-[15px] rounded-[8px]  bg-[#30303033]"
                            style={{
                                boxShadow: "0px 1px 3px 0px #000000",
                                border: "1px solid #FFFFFF0D",
                            }}
                        >
                            <div className=" flex items-center justify-between mb-[31px]">
                                {" "}
                                <p className="sm:text-[18px] text-[12px]">Energy Data Graph</p>
                                <select
                                    className="border border-[#FFFFFF08] text-white custom-select bg-[#FFFFFF0D] w-[102px]  h-[34px] text-[13px] rounded-[8px]">
                                    <option>2020</option>
                                    <option>2021</option>
                                    <option>2022</option>
                                    <option>2023</option>
                                    <option>2024</option>
                                </select>
                            </div>
                            <div className="  line-chart ">
                                <EnergyGraph/>
                            </div>
                        </div>
                        <div className="mt-4">
                            <EnergyDataTable isAIMood={isAIMood}/>
                        </div>
                        <div className="mt-4">
                            <AINonConsumption/>
                        </div>
                    </div>
                    <div className="grid lg:col-span-4 col-span-12">
                        <div>
                            <div className="h-fit w-[100%] relative ">
                                <img src="/Frame 65.png" alt="" className="w-[100%]"/>
                                <div className="w-[100%] h-[100%] p-[10px] absolute top-0">
                                    <div className="flex justify-between flex-col  h-[100%] ">
                                        <div className="flex justify-between">
                                            <div>
                                                <p className="text-[#D8D8D8] text-[13px] font-[600]">
                                                    8th Floor Apartment
                                                </p>
                                                <p className="text-[12px] text-[#B0B0B0] ">876Sqft</p>
                                            </div>
                                            <img src="/Mask group (2).svg" alt=""/>
                                        </div>
                                        <div className="flex justify-between items-end">
                                            <div className="flex items-center">
                                                <img src="/Vector (8).svg" alt=""/>
                                                <p className="text-[12px] font-medium ml-[7px]  ">
                                                    No Risk Detected
                                                </p>
                                            </div>
                                            <div className="flex flex-col gap-[5px]">
                                                <div className="flex items-center">
                                                    <p className="text-[#8A8D9C] font-[600] text-[13px]">
                                                        X
                                                    </p>
                                                    <div
                                                        className="w-[32px] h-[32px] ml-2 rounded-[8px] bg-[#FFFFFF0D] border border-[#FFFFFF08] flex items-center justify-center text-[13px] ">
                                                        58
                                                    </div>
                                                </div>
                                                <div className="flex items-center">
                                                    <p className="text-[#8A8D9C] font-[600] text-[13px]">
                                                        Y
                                                    </p>
                                                    <div
                                                        className="w-[32px] h-[32px] ml-2 rounded-[8px] bg-[#FFFFFF0D] border border-[#FFFFFF08] flex items-center justify-center text-[13px] ">
                                                        128
                                                    </div>
                                                </div>
                                                <div className="flex items-center">
                                                    <p className="text-[#8A8D9C] font-[600] text-[13px]">
                                                        Z
                                                    </p>
                                                    <div
                                                        className="w-[32px] h-[32px] ml-2 rounded-[8px] bg-[#FFFFFF0D] border border-[#FFFFFF08] flex items-center justify-center text-[13px] ">
                                                        32
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    className=" text-[#D8D8D8] mt-4 bg-[#152229] dark:bg-[#FFFFFF0D]  text-[13px] overflow-hidden   rounded-[8px] ">
                                    <div className=" pt-[20px] flex justify-between items-center px-[15px]">
                                        <p className="sm:text-[18px] text-[12px] font-semibold">
                                            Section Comparison
                                        </p>
                                        <select
                                            className="border border-[#FFFFFF08] custom-select bg-[#FFFFFF0D] w-[102px] h-[34px] text-[13px] rounded-[8px]">
                                            <option>Monthly</option>
                                            <option>Yearly</option>
                                            <option>Daily</option>
                                        </select>
                                    </div>
                                    <div className="flex justify-between items-center mt-[23px] py-2 px-[15px]">
                                        <p className="text-[#9BB9A9] text-[10px] uppercase">
                                            Section
                                        </p>
                                        <p className="uppercase text-[#9BB9A9] text-[10px]">
                                            Energy (kWh)
                                        </p>
                                    </div>
                                    <div
                                        className="flex justify-between gap-7 text-[#D8D8D8] text-[13px] bg-[#FFFFFF0D] dark:bg-[#FFFFFF0D] py-[9px] px-[15px]  ">
                                        <div className=" text-[#9BB9A9]">Basement</div>
                                        <div className="">4500</div>
                                    </div>
                                    <div
                                        className="flex justify-between gap-7 text-[#D8D8D8] text-[13px] bg-[#152229] dark:bg-[#112025] py-[9px] px-[15px]  ">
                                        <div className=" text-[#9BB9A9]">Ground Floor</div>
                                        <div className="">4500</div>
                                    </div>
                                    <div
                                        className="flex justify-between gap-7 text-[#D8D8D8] text-[13px] bg-[#222d34] dark:bg-[#FFFFFF0D] py-[9px] px-[15px]   ">
                                        <div className=" text-[#9BB9A9]">1st Floor</div>
                                        <div className="">4550</div>
                                    </div>
                                    <div
                                        className="flex justify-between gap-7 text-[#D8D8D8] text-[13px] bg-[#152229] dark:bg-[#112025] py-[9px] px-[15px]   ">
                                        <div className=" text-[#9BB9A9]">2nd Floor</div>
                                        <div className="">4600</div>
                                    </div>
                                    <div
                                        className="flex justify-between gap-7 text-[#D8D8D8] text-[13px] bg-[#222d34] dark:bg-[#FFFFFF0D] py-[9px] px-[15px]   ">
                                        <div className=" text-[#9BB9A9]">3rd Floor</div>
                                        <div className="">4550</div>
                                    </div>
                                    <div
                                        className="flex justify-between gap-7 text-[#D8D8D8] text-[13px] bg-[#152229] dark:bg-[#112025] py-[9px] px-[15px]   ">
                                        <div className=" text-[#9BB9A9]">4rd Floor</div>
                                        <div className="">4600</div>
                                    </div>
                                    <div
                                        className="flex justify-between gap-7 text-[#D8D8D8] text-[13px] bg-[#222d34] dark:bg-[#FFFFFF0D] py-[9px] px-[15px]   ">
                                        <div className=" text-[#9BB9A9]">5th Floor</div>
                                        <div className="">4600</div>
                                    </div>
                                    <div
                                        className="flex justify-between gap-7 text-[#D8D8D8] text-[13px] bg-[#152229] dark:bg-[#112025] py-[9px] px-[15px]   ">
                                        <div className=" text-[#9BB9A9]">6th Floor</div>
                                        <div className="">4600</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div>*/}
                {/*    <div className="flex items-center justify-end">*/}
                {/*        <div className="flex items-end justify-center pt-4">*/}
                {/*            <small className={`mr-2`}>Developed by</small>*/}
                {/*            <a href="https://mettlesoft.com.au" target="_blank">*/}
                {/*                <img src="/mettlesoft.png" alt='Mettlesoft' className={` h-[30px]`}/>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}
