import React from "react";

export default function DiplayMenu() {
  return (
    <div>
      <div className=" relative z-30 ">
        <div>
          <div
            className="xl:h-[50px] h-[30px] xl:w-[90px] rounded-[8px]  absolute xl:top-[-50px] top-[-30px] left-[10px]  "
            style={{
              borderLeft: "1px dashed #5d776e",
              borderTop: "1px dashed #5d776e",
            }}
          ></div>
          <div className="xl:top-[-100px] top-[-160px] xl:left-[100px] left-[-80px] absolute border border-[#3a4e4b] rounded-[8px] overflow-hidden">
            <div className="bg-[#0e2821] w-[195px] p-4 ">
              <img src="/col.svg" alt="" />
              <p className="text-[#D8D8D8] text-[13px] font-semibold">
                AC Cooling Indoor Unit
              </p>
              <p className="text-[12px] text-[#9BB9A9] ">Central AC Line </p>
            </div>
            <div
              style={{ backdropFilter: "blur(5px)" }}
              className="bg-[#284137] py-2 px-3 flex items-start "
            >
              <img src="main.svg" alt="" className="mt-[2px]" />
              <p className="ml-2 text-[12px] font-[500]">
                Maintenance due in 3 <br /> days
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
