import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  Filler, // add this if you want to fill the area under the line chart
} from "chart.js";
import { useSelector } from "react-redux";

// Register the necessary components
ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  Filler // add this if you want to fill the area under the line chart
);

const LineChart = () => {
  const saveenergyformonth=useSelector((state)=>state.energy.energyValueForMonth)

  const data = {
    labels: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
    ],
    datasets: [
      {
        label: "My First Dataset",
        data: saveenergyformonth ? saveenergyformonth : [],
        fill: false,
        borderColor: "#7aae89",
        tension: 0.1,
        pointRadius: function (context) {
          const index = context.dataIndex;
          // Set point radius to 5 for July (index 6), 0 for others
          return index === 6 ? 5 : 0;
        },
        pointBackgroundColor: function (context) {
          const index = context.dataIndex;
          // Set point background color to red for July (index 6), default for others
          return index === 6 ? "#EC6B5F" : "rgba(0, 0, 0, 0)";
        },
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Disable the legend
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default LineChart;
