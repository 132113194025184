import React from "react";
import Sidebar from "../../components/sidebar";
import LineChart from "../../components/chart";
import { categoryUsingArray } from "../../data";
import { useNavigate } from "react-router-dom";
import Drawer from "../../components/drawer";

export default function ElantraTower({
  setIsMenu,
  isMenu,
  setShow,
  setIsSetting,
  isAIMood,
  setIsAIMood,
  total,
}) {
  const navigate = useNavigate();
  return (
    <div
      className="w-[100%] bg-no-repeat h-[100vh]  bg-contain bg-center-bottom flex relative"
      style={{ backgroundImage: "url(/Building.png)" }}
    >
      <Sidebar
        setIsMenu={setIsMenu}
        isMenu={isMenu}
        setShow={setShow}
        setIsSetting={setIsSetting}
      />
      <div className=" h-[100vh] w-[100%] overflow-scroll my-scrollar">
        <div className="xl:p-[0px] p-[12px]">
          <Drawer />
        </div>
        <div className="grid grid-cols-12">
          <div className="grid lg:col-span-8 md:col-span-6 col-span-12  md:p-[24px] p-[12px]">
            <div className="flex items-center absolute">
              <div
                onClick={() => navigate("/")}
                className="w-[34px] h-[34px] cursor-pointer bg-[#FFFFFF0D] rounded-[3px] border border-[#FFFFFF08] flex items-center justify-center"
              >
                <img src="/chevron.down.svg" alt="" />
              </div>
              <div className="ml-[24px]">
                <p className="lg:text-[34px] text-[20px] font-medium lg:leading-[41px] leading-[25px]">
                  Elantra Tower
                </p>
                <p className="text-[#9BB9A9] text-[13px] font-[500]">
                  16 Floor
                </p>
              </div>
            </div>
            <div>{/* <img src="/Group 22 (2).png" alt="" /> */}</div>
            <div className="flex items-center absolute bottom-[20px]">
              <div className="max-w-[125px] w-[100%]">
                <p className="text-[#D8D8D8] text-[13px] font-[600]">
                  Flood Risk
                </p>
                <div
                  style={{ backdropFilter: "blur(5px)" }}
                  className="py-[8px] pl-[12px] pr-[30px] bg-[#B1DF8E1F] w-[125px]  rounded-[8px] flex items-center mt-[7px]"
                >
                  <img src="/flood.svg" alt="" />
                  <div className="ml-[12px]">
                    <p className="text-[24px] font-[600]">
                      0 <span className="text-[13px] ">/5</span>
                    </p>
                    <p className="text-[#9BB9A9] text-[12px] ">Low Risk</p>
                  </div>
                </div>
              </div>
              <div className="ml-4 max-w-[125px] w-[100%]">
                <p className="text-[#D8D8D8] text-[13px] font-[600]">
                  Fire Risk
                </p>
                <div
                  style={{ backdropFilter: "blur(5px)" }}
                  className="py-[8px] pl-[12px] pr-[30px] bg-[#B1DF8E1F]  rounded-[8px] flex items-center mt-[7px]"
                >
                  <img src="/fire.svg" alt="" />
                  <div className="ml-[12px]">
                    <p className="text-[24px] font-[600]">
                      0 <span className="text-[13px] ">/5</span>
                    </p>
                    <p className="text-[#9BB9A9] text-[12px] ">Minimal</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ backdropFilter: "blur(10px)" }}
            className="grid lg:col-span-4 md:col-span-6 col-span-12 bg-[#051A1475] pt-[24px] px-[22px]"
          >
            <div>
              <p className="text-[28px] font-[500]">78 Floor , 4B</p>
              {/* <div
                className="py-[20px] px-[13px] w-[100%] mt-[18px] h-fit rounded-[8px] bg-[#FFFFFF0D] border border-[#FF8B38]"
                style={{ boxShadow: "0px 1px 3px 0px #000000" }}
              >
                <div className="flex items-center">
                  <img src="/warrning.svg" alt="" />
                  <div className="ml-[13px]">
                    <p className="text-[#FF553E] font-bold text-[13px]">
                      Warning
                    </p>
                    <p className="text-[#B0B0B0]  text-[12px] ">
                      Thermostats is not working properly
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="flex items-center justify-end">
                <div className="lg:flex items-center justify-center mr-4 hidden">
                  <small className="mr-2">Partnered by</small>
                                <img src="/tuv-rheinland-logo.png" alt='TUV' className={`h-[36px] px-2 py-1 rounded bg-[#ffffff]`}/>
                </div>
              <div class="gradient-border py-[12px] px-[16px] mt-[25px] ">
                <div className="flex items-center">
                  <p className="text-[#D8D8D8] font-[600] text-[13px]">
                    AI Savings
                  </p>
                  <label class="inline-flex items-center cursor-pointer ml-2">
                    <input
                      type="checkbox"
                      checked={isAIMood}
                      onChange={(event) => setIsAIMood(event.target.checked)}
                      class="sr-only peer focus-visible:outline-none"
                    />
                    <div class="relative w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:bg-[#336633]  after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gradient-to-r peer-checked:from-[#99CC33] peer-checked:to-[#00C5A2]"></div>
                  </label>
                </div>
              </div>
              </div>
              <p className="text-[#D8D8D8] font-[700] text-[13px] mt-[16px]">
                Critical Energy Information
              </p>
              <div
                style={{
                  boxShadow: " 0px 1px 3px 0px #000000",
                  border: "1px solid #FFFFFF0D",
                }}
                className="rounded-[8px] mt-[8px]"
              >
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.01) 100%)",
                  }}
                  className="grid grid-cols-12 xl:gap-7 gap-3 text-[#D8D8D8]  text-[13px] py-[9px] px-[15px] rounded-t-[8px] "
                >
                  <div className="grid col-span-6">Item</div>
                  <div className="grid col-span-3">Value</div>
                  <div className="grid col-span-3">Status</div>
                </div>
                <div className="grid grid-cols-12 xl:gap-7 gap-3 text-[#D8D8D8] text-[13px] bg-[#FFFFFF0D] py-[9px] px-[15px]  ">
                  <div className="grid col-span-6 text-[#A0A0A0]">
                    Current energy consumption
                  </div>
                  <div className="grid col-span-3">{total?.toFixed()}Kwh</div>
                  <div className="grid col-span-3">
                    <div className="flex items-center">
                      <div
                        className={`w-[8px] h-[8px]  rounded-full bg-[#EC6B5F]`}
                      />
                      <p className="ml-1">Little High</p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 xl:gap-7 gap-3 text-[13px] text-[#D8D8D8] bg-[#FFFFFF00] py-[9px] px-[15px]  ">
                  <div className="grid col-span-6 text-[#A0A0A0]">
                    Peak consumption
                  </div>
                  <div className="grid col-span-3">482Kwh</div>
                  <div className="grid col-span-3">
                    <div className="flex items-center">
                      <div
                        className={`w-[8px] h-[8px]  rounded-full bg-[#99CC33]`}
                      />
                      <p className="ml-1">Normal</p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 xl:gap-7 gap-3 text-[13px] text-[#D8D8D8] bg-[#FFFFFF0D] rounded-b-[8px] py-[9px] px-[15px]  ">
                  <div className="grid col-span-6 text-[#A0A0A0]">
                    Energy cost
                  </div>
                  <div className="grid col-span-3">3 USD/Kw</div>
                  <div className="grid col-span-3">
                    <div className="flex items-center">
                      <div
                        className={`w-[8px] h-[8px]  rounded-full bg-[#99CC33]`}
                      />
                      <p className="ml-1">Normal</p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-[#D8D8D8] font-[700] text-[13px] mt-[16px]">
                Consumption Graph
              </p>
              <div
                className=" p-[15px] rounded-[8px]  mt-[8px] bg-[#30303033] line-chart"
                style={{
                  boxShadow: "0px 1px 3px 0px #000000",
                  border: "1px solid #FFFFFF0D",
                }}
              >
                <LineChart />
              </div>
              <p className="text-[#D8D8D8] font-[700] text-[13px] mt-[16px]">
                Consumption Graph
              </p>
              <div
                className="bg-[#30303033] border border-[#FFFFFF0D] mt-4 rounded-[8px]"
                style={{ boxShadow: "0px 1px 3px 0px #000000" }}
              >
                <div className="p-4">
                  <div className="flex items-center">
                    <img
                      src="/3d-earth.png"
                      alt=""
                      className="h-[51px] w-[51px]"
                    />
                    <div className="ml-2">
                      <p className="text-[15px] text-[#D8D8D8] font-[700]">
                        Uses are Under Control
                      </p>
                      <p className=" text-[12px] mt-[2px]">
                        <span className="font-[600] text-[#9BB9A9]">
                          {" "}
                          Total
                        </span>{" "}
                        10200 kgCO2e
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.01) 100%)",
                  }}
                  className="mt-[23px] py-[9px] px-[15px] flex justify-between items-center rounded-t-[8px] text-[13px] font-medium"
                >
                  <p className="text-[#D8D8D8]">Category</p>
                  <p className="text-[#D8D8D8]">Carbon Emissions (kgC02e)</p>
                </div>
                {categoryUsingArray.map((item, index) => (
                  <div
                    key={index}
                    className={`${
                      index % 2 === 1 ? "bg-[#FFFFFF00]" : "bg-[#FFFFFF0D]"
                    }   py-[9px] px-[15px] flex justify-between items-center  text-[13px] `}
                  >
                    <div className="flex items-center ">
                      <div
                        style={{ background: item?.color }}
                        className={`w-[8px] h-[8px]  rounded-full`}
                      />
                      <p className="text-[#9BB9A9] ml-1">{item?.title}</p>
                    </div>
                    <div className="flex items-center">
                      <p className="text-[#D8D8D8]">{item?.value}</p>
                      {isAIMood && (
                        <p className="text-[#99CC33] ml-1">{item?.ai}</p>
                      )}
                      {isAIMood && (
                        <img src="/percent.svg" alt="" className="ml-[2px]" />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
