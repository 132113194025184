import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  energyValueForMonth: [],
}

export const energySlice = createSlice({
  name: 'energy',
  initialState,
  reducers: {
    saveenergyformonth: (state,action) => {
      state.energyValueForMonth=action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { saveenergyformonth } = energySlice.actions

export default energySlice.reducer