import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Drawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="h-[60px] bg-[#2B4544CC] xl:hidden flex items-center justify-between px-[20px] rounded-[8px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
          onClick={toggleDrawer}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>

        <div className="flex items-center justify-center mr-4 ld:hidden">
          <small className="mr-2 text-[8px]">Partnered by</small>
          <img src="/tuv-rheinland-logo.png" alt='TUV' className={`h-[24px] px-[4px] py-[3px] rounded bg-[#ffffff]`}/>
        </div>
      </div>

      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ${
          isOpen ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
        onClick={toggleDrawer}
      ></div>

      <div
        className={`fixed top-0 left-0 h-full w-64 bg-[#2B4544] z-50 shadow-lg transform transition-transform duration-300 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div
          className={`
       h-[100vh] overflow-scroll my-scrollar pl-[25px]   flex-col bg-[#2B4544CC] pt-[27px] pb-[39px] transition-all duration-300`}
        >
          <div className="">
            <img src="/logo.svg" alt="Logo" onClick={() => navigate("/")} />

            <div className="mt-[62px]">
              <div
                onClick={() => navigate("/")}
                className="flex items-center cursor-pointer"
              >
                <img
                  src="/home.svg"
                  alt="Home"
                  className="cursor-pointer"
                  onClick={() => navigate("/")}
                />
                <p className={`text-[#9BB9A9] text-[13px] ml-3 `}>Home </p>
              </div>
              <div className="flex items-center mt-[35px]">
                <img
                  onClick={() => navigate("/equipment-monitoring")}
                  src="/nature.svg"
                  alt="Nature"
                  className=" cursor-pointer"
                />
                <p className={`text-[#9BB9A9] text-[13px] ml-3 `}>Emissions </p>
              </div>
              <div
                onClick={() => navigate("/equipment-monitoring")}
                className="mt-[35px] flex items-center cursor-pointer"
              >
                <img
                  src="/browse.svg"
                  alt="Browse"
                  className=" cursor-pointer"
                />
                <p
                  className={`text-[#9BB9A9] text-[13px] whitespace-nowrap ml-3 `}
                >
                  Equipment Monitoring{" "}
                </p>
              </div>
              <div
                onClick={() => navigate("/energy-consumption")}
                className="mt-[35px] flex items-center cursor-pointer"
              >
                <img
                  onClick={() => navigate("/energy-consumption")}
                  src="/offline.svg"
                  alt="Offline"
                  className=" cursor-pointer"
                />
                <p
                  className={`text-[#9BB9A9] text-[13px] whitespace-nowrap ml-3 `}
                >
                  Energy Consumption{" "}
                </p>
              </div>
              <div
                onClick={() => navigate("/equipment-monitoring1")}
                className="flex items-center mt-[35px] cursor-pointer"
              >
                <img
                  onClick={() => navigate("/equipment-monitoring1")}
                  src="/analytics.svg"
                  alt="Analytics"
                  className=" cursor-pointer"
                />
                <p className={`text-[#9BB9A9] text-[13px] ml-3 `}>Reports </p>
              </div>
            </div>
          </div>
          <div className="mt-[100px]">
            <div className={`flex items-center`}>
              <img src="/User.svg" alt="User" className="cursor-pointer" />
              <p className={`text-[#9BB9A9] text-[13px] ml-3 `}>Profile </p>
            </div>
            <div className="flex items-center mt-[35px] ">
              <img
                src="/setting.svg"
                alt="Settings"
                className=" cursor-pointer"
              />
              <p className={`text-[#9BB9A9] text-[13px] ml-3 `}>Profile </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
