import React, { useState } from "react";

export default function NotificationSetting() {
  const notificationArray = [
    "Energy Consumption Alert",
    "Maintenance Reminders",
    "Report Availibility",
  ];
  const [selectedAlert, setSelectedAlert] = useState([]);
  const handleSelected = (data) => {
    if (selectedAlert.includes(data)) {
      setSelectedAlert(selectedAlert.filter((item) => item !== data));
    } else {
      setSelectedAlert((pre) => [...pre, data]);
    }
  };
  const [browserNoti, setBrowserNoti] = useState(true);
  return (
    <div>
      <div>
        <div>
          <p className="text-[#9BB9A9] text-[10px]">EMAIL NOTIFICATION</p>
          <div className="flex flex-wrap gap-2 mt-2">
            {notificationArray.map((item) => (
              <div
                onClick={() => handleSelected(item)}
                className="flex items-center"
              >
                <div
                  className={`flex items-center  cursor-pointer border border-[#FFFFFF1C] text-[13px] ${
                    selectedAlert.includes(item) && "bg-[#2B383F]"
                  }  px-[5px] py-[3px] w-fit rounded-[8px]`}
                >
                  {selectedAlert.includes(item) && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-3 mr-1 text-[#99CC33]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m4.5 12.75 6 6 9-13.5"
                      />
                    </svg>
                  )}

                  <p>{item}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-8">
          <p className="text-[#9BB9A9] text-[10px]">BROWSER NOTIFICATION</p>
          <label class="inline-flex items-center cursor-pointer mt-2">
            <input
              type="checkbox"
              checked={browserNoti}
              onClick={(event) => setBrowserNoti(event.target.checked)}
              class="sr-only peer focus-visible:outline-none"
            />
            <div class="relative w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:bg-[#336633]  after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gradient-to-r peer-checked:from-[#99CC33] peer-checked:to-[#00C5A2]"></div>
          </label>
        </div>
      </div>
    </div>
  );
}
