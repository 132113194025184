import "./App.css";
import Home from "./pages/home";
import EquipmentMonitoring from "./pages/equipmentMonitoring";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {useEffect, useState} from "react";
import EquipmentMonitor1 from "./pages/equipmentMonitor-1";
import EnergyConsumption from "./pages/energyConsumption";
import Modal from "./components/profileModal";
import SettingModal from "./components/settingModal";
import ElantraTower from "./components/eltantra";

function App() {
  const [isMenu, setIsMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [isSetting, setIsSetting] = useState(false);
  const [isAIMood, setIsAIMood] = useState(false);
  const [total, setTotal] = useState(0);
  const [sumPower, setSomePower] = useState(0);
  const array1 = [
    "28975273665966080",
    "28975281808093184",
    "28975286083399680",
    "28975293437456384",
    "29762497653219328",
  ];

  const url = "http://13.212.150.127:13333/api/Ext_AdminData/GetData";
  const token =
    "1B195C7E6543D3DDF8832388CCD2917B7DE98EB736984E8698248B4EE3742646";

  const headers = {
    "Content-Type": "application/json",
    token: token,
  };

  const fetchData1 = async (id) => {
    const requestData = {
      id: id,
      lastcount: 1,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestData),
      });

      console.log('Energy API >>>>>>>>>>> ',response);
      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }

      return await response.json(); // Assuming the data contains the value you want to sum
    } catch (error) {
      return 0; // Return 0 in case of error to ensure it doesn't affect the sum
    }
  };

  const calculateTotal1 = async () => {
    let sum = 0;
    for (let id of array1) {
      const result = await fetchData1(id);
      if (result) {
        sum += result?.Data[0]?.vue || 0; // Adjust this if the data structure is different
      }
    }
    setTotal(sum);
  };


  useEffect(() => {
 
    const interval = setInterval(() => {
      calculateTotal1();
    }, 5000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const array2 = [
    "28975277568897024",
    "28975283824832512",
    "28975291227844608",
    "28975296029863936",
    "29762502325411840",
  ];

  const url2 = "http://13.212.150.127:13333/api/Ext_AdminData/GetData";


  const fetchData = async (id) => {
    const requestData = {
      id: id,
      lastcount: 1,
    };

    try {
      const response = await fetch(url2, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }

      const data = await response.json();
      return data; // Assuming the data contains the value you want to sum
    } catch (error) {
      return 0; // Return 0 in case of error to ensure it doesn't affect the sum
    }
  };

  const calculateTotal = async () => {
    let sum = 0;
    for (let id of array2) {
      const result = await fetchData(id);
      if (result) {
        sum += result?.Data[0]?.vue || 0; // Adjust this if the data structure is different
      }
    }
    setSomePower(sum);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      calculateTotal();
    }, 5000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Router>
        <Modal show={show} onClose={() => setShow(false)}></Modal>
        <SettingModal show={isSetting} onClose={() => setIsSetting(false)} />
        <div className="flex">
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  setIsMenu={setIsMenu}
                  isMenu={isMenu}
                  setShow={setShow}
                  setIsSetting={setIsSetting}
                  isAIMood={isAIMood}
                  setIsAIMood={setIsAIMood}
                  total={total}
                  sumPower={sumPower}
                />
              }
            />
            <Route
              path="equipment-monitoring"
              element={
                <EquipmentMonitoring
                  setIsMenu={setIsMenu}
                  isMenu={isMenu}
                  setShow={setShow}
                  setIsSetting={setIsSetting}
                  isAIMood={isAIMood}
                  setIsAIMood={setIsAIMood}
                  total={total}
                />
              }
            />
            <Route
              path="equipment-monitoring1"
              element={
                <EquipmentMonitor1
                  setIsMenu={setIsMenu}
                  isMenu={isMenu}
                  setShow={setShow}
                  setIsSetting={setIsSetting}
                  isAIMood={isAIMood}
                  setIsAIMood={setIsAIMood}
                />
              }
            />
            <Route
              path="energy-consumption"
              element={
                <EnergyConsumption
                  setIsMenu={setIsMenu}
                  isMenu={isMenu}
                  setShow={setShow}
                  setIsSetting={setIsSetting}
                  isAIMood={isAIMood}
                  setIsAIMood={setIsAIMood}
                />
              }
            />
            <Route
              path="elantra-tower"
              element={
                <ElantraTower
                  setIsMenu={setIsMenu}
                  isMenu={isMenu}
                  setShow={setShow}
                  setIsSetting={setIsSetting}
                  isAIMood={isAIMood}
                  setIsAIMood={setIsAIMood}
                  total={total}
                />
              }
            />
            {/* <Route
              path="elantra-tower"
              element={
                <ElantraTower
                  setIsMenu={setIsMenu}
                  isMenu={isMenu}
                  setShow={setShow}
                  setIsSetting={setIsSetting}
                />
              }
            /> */}
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
