import React from "react";
import { useSelector } from "react-redux";

export default function AINonConsumption() {
  const saveenergyformonth=useSelector((state)=>state.energy.energyValueForMonth)

  const tableData2 = [
    {
      ID: "1",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "2",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "3",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "4",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "5",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "6",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "7",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "8",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "9",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "10",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "11",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "12",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "13",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "14",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },{
      ID: "15",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "16",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "17",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "18",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "19",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "20",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "21",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "22",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "23",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "24",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "25",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "26",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "27",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "28",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "29",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "30",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
  ];
  const tableData = [
    {
      ID: "Jan",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "Feb",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "Mar",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "Apr",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "May",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "Jun",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "Jul",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "Aug",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "Sep",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "Oct",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "Nov",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "Dec",
      Type: "Chiller",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
  ];

  // saveenergyformonth?.map((item,index)=>{
  //   tableData[index].Type=(item*0.5).toFixed(2)
  // })

  saveenergyformonth?.map((item,index)=>{
    if (index < 12) {
      tableData[index].Type=(item*0.5).toFixed(2)
      tableData[index].Location=item.toFixed(2)
    }
  })

 
  return (
    <div>
      <div
        className="bg-[#FFFFFF0D] border border-[#FFFFFF0D] rounded-lg"
        style={{ boxShadow: "0px 1px 3px 0px #000000" }}
      >
        <div className="px-[20px] pt-[20px] flex justify-between items-center">
          <p className="sm:text-[18px] text-[12px] font-semibold">
            AI vs. Non-AI Consumption
          </p>
          <select className="border custom-select border-[#FFFFFF08] bg-[#FFFFFF0D] w-[102px] h-[34px] text-[13px] rounded-[8px]">
            <option>Monthly</option>
            <option>Yearly</option>
            <option>Daily</option>
          </select>
        </div>

        <div className="relative overflow-x-auto sm:rounded-lg mt-[23px] h-[250px] overflow-y-auto my-scrollar">
          <table className="w-full text-sm text-left ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th
                  scope="col"
                  className="px-[20px] font-normal py-3 text-[#9BB9A9] "
                >
                  TIME PERIOD
                </th>
                <th scope="col" className="py-3">
                  <div className="flex items-center uppercase  whitespace-nowrap font-normal text-[#9BB9A9]">
                    AI-ASSISTED (KWH)
                  </div>
                </th>
                <th
                  scope="col"
                  className="py-3 flex items-center  uppercase  font-normal text-[#9BB9A9]"
                >
                  <div className="flex items-center">NON-AI (KWH)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 1 ? "bg-[#FFFFFF00] " : "bg-[#FFFFFF0D]"
                  } `}
                >
                  <th className="px-[20px]   py-2 font-medium text-white whitespace-nowrap ">
                    {item?.ID}
                  </th>
                  <td className="  py-2  text-left">{item?.Type}</td>
                  <td className="py-2">{item?.Location}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
