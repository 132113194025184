export const categoryUsingArray = [
  {
    title: "Electricity Use",
    value: "5000",
    color: "#99CC33",
    ai: "5%",
  },
  {
    title: "Natural Gas Use",
    value: "3000",
    color: "#FF8B38",
    ai: "2%",
  },
  {
    title: "Heating Oil Use",
    value: "1500",
    color: "#3887FF",
    ai: "3%",
  },
  {
    title: "Water Use",
    value: "200",
    color: "#B94D4D",
    ai: "7%",
  },
  {
    title: "Waste Generation",
    value: "100",
    color: "#38F3FF",
    ai: "14%",
  },
  {
    title: "Transportation",
    value: "400",
    color: "#737ABB",
    ai: "8%",
  },
];

export const siderbarArray = [
  {
    icon: "/home1.svg",
    icon1: "/home.svg",
    title: "Home",
    route: "/",
  },
  {
    icon: "/nature.svg",
    title: "Emissions",
    icon1: "/emmision.svg",
  },
  {
    icon: "/browse.svg",
    title: "Equipment Monitoring",
    icon1: "/browser.svg",

    route: "/equipment-monitoring1",
  },
  {
    icon: "/offline.svg",
    icon1: "/offline1.svg",

    title: "Energy Consumption",
    route: "/energy-consumption",
  },
  {
    icon: "/analytics.svg",
    icon1: "/analysis.svg",

    title: "Reports",
  },
];
