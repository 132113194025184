import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
const Modal = ({ show, onClose }) => {
  const navigate = useNavigate();
  const user_imagepath=localStorage.getItem('user_image')
  const [selectedFiles, setSelectedFiles] = useState("");
  // const [filepath, setfilepath] = useState("");
  const [filepath, setfilepath] = useState(user_imagepath);
  const handleChangeimg = (event) => {
    const inputElement = event?.target;
    if (inputElement?.files) {
      const files = inputElement?.files;
      setSelectedFiles(files[0]);
      const reader = new FileReader();
      if (event.target.files[0]) {
        reader.readAsDataURL(files[0]);
        reader.onload = function () {
          localStorage.setItem('user_image',reader?.result)
          setfilepath(reader?.result);
        };
      }
    }
  };
  // console.log("selectedFiles", selectedFiles);
  if (!show) return null;
  return (
    <>
      <div
        className="fixed inset-0 bg-black cursor-pointer opacity-90 flex justify-center items-center z-40 w-[100%] h-[100vh]"
        onClick={onClose}
      ></div>
      <div className="fixed inset-0   z-50  h-fit max-w-[403px] m-auto rounded-[12px] bg-[#15232A]  p-[32px] ">
        <div className="flex items-center justify-between w-[100%]">
          <p className="font-semibold text-[18px]">My Profile</p>
        </div>
        <div className="flex justify-center mt-[11px]">
          <label className="relative group">
            <img
              src={filepath !== "" ? filepath : "/User (1).svg"}
              alt=""
              className="w-[71px] hover:cursor-pointer rounded-full h-[71px]"
            />
            <input
              id="dropzone-front"
              type="file"
              name="front"
              className="hidden"
              onChange={handleChangeimg}
            />
            <div className="absolute top-0 left-0 rounded-full cursor-pointer w-full h-full flex items-center justify-center bg-[#0000008e] opacity-0 group-hover:opacity-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                />
              </svg>
            </div>
          </label>
        </div>
        <div className="mt-[17px]">
          <p className="text-[10px] text-[#9BB9A9] uppercase ">Profile ID</p>
          <input
            type="text"
            placeholder="profile id"
            className=" border border-[#FFFFFF08] indent-3 bg-[#FFFFFF0D] h-[42px] w-full rounded-[8px] mt-[4px] focus-visible:outline-none"
          />
        </div>
        <div className="mt-[16px]">
          <p className="text-[10px] text-[#9BB9A9] uppercase ">Password</p>
          <input
            type="password"
            placeholder="password"
            className=" border border-[#FFFFFF08] indent-3 bg-[#FFFFFF0D] h-[42px] w-full rounded-[8px] mt-[4px] focus-visible:outline-none"
          />
        </div>
        <button className="h-[42px] rounded-[12px] bg-[#99CC33] w-full mt-[49px]">
          Log Out
        </button>
      </div>
    </>
  );
};

export default Modal;
