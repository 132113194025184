import React, { useEffect, useState } from "react";

export default function EquipmentListTable() {
  const [statuses,setStatuses]=useState([])
  const tableData = [
    {
      ID: "HVAC-05",
      Type: "Meter 1",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "HVAC-05",
      Type: "Meter 2",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "HVAC-05",
      Type: "Meter 3",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "HVAC-05",
      Type: "Meter 4",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "HVAC-05",
      Type: "Meter 5",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    }
    
  ];


  const energyArray = [
    '28975273665966080',
    '28975281808093184',
    '28975286083399680',
    '28975293437456384',
    '29762497653219328'
  ];
  
  // Assuming tableData is defined elsewhere
  energyArray.forEach((id, index) => {
    tableData[index].ID = id;
  });
  if(statuses>1){
    statuses?.map((status,index)=>{
      if(!status==="success"){
        tableData[index].Status = 'Non-Operational';
      }
    })
  } 
  const token = "1B195C7E6543D3DDF8832388CCD2917B7DE98EB736984E8698248B4EE3742646";
  const headers = {
    "Content-Type": "application/json",
    "token": token,
  };
  
  const url = 'http://13.212.150.127:13333/api/Ext_AdminData/GetData';
  
  const fetchData = async (id) => {
    const requestData = {
      id: id,
      lastcount: 1,
    };
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestData),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }
  
      const data = await response.json();
      return data; // Assuming the data contains the value you want to handle
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error; // Rethrow the error to handle it in fetchDataForAll
    }
  };
  
  const fetchDataForAll = async () => {
    try {
      const promises = energyArray.map(id => fetchData(id));
      const responses = await Promise.all(promises);
      const statuses=responses.map((response)=>{
        return response.Msg
      })
      return statuses
    } catch (error) {
      console.error("Error fetching all data:", error);
      // Handle error if needed, e.g., retry mechanism
    }
  };
  
  useEffect(() => {
    const interval = setInterval(() => {
      const statuses=fetchDataForAll();
      setStatuses(statuses)

    }, 5000);
  
    return () => clearInterval(interval);
  }, []);

  


  return (
    <div>
      <div
        className="bg-[#FFFFFF0D] border border-[#FFFFFF0D] rounded-lg"
        style={{ boxShadow: "0px 1px 3px 0px #000000" }}
      >
        <div className="pl-[20px] pt-[20px]">
          <p className="text-[18px] font-semibold">Equipment List</p>
        </div>

        <div className="relative overflow-x-auto sm:rounded-lg mt-[23px] h-[230px] my-scrollar overflow-y-auto">
          <table className="w-full text-sm text-left ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th
                  scope="col"
                  className="px-[20px] font-normal py-3 text-[#9BB9A9] "
                >
                  EQUIPMENT ID
                </th>
                <th scope="col" className="py-3">
                  <div className="flex items-center uppercase  font-normal text-[#9BB9A9]">
                    Name
                  </div>
                </th>
                <th
                  scope="col"
                  align="right"
                  className="py-3 flex items-center  uppercase  font-normal text-[#9BB9A9]"
                >
                  <div className="flex items-center">Location</div>
                </th>
                <th
                  scope="col"
                  className="py-3 uppercase font-normal  text-[#9BB9A9]"
                >
                  <div className="flex items-center">Status</div>
                </th>
                <th
                  scope="col"
                  className="py-3 uppercase font-normal  text-[#9BB9A9]"
                >
                  <div className="flex items-center whitespace-nowrap">
                    Last Maintenance
                  </div>
                </th>
                <th
                  scope="col"
                  className="py-3 uppercase font-normal  text-[#9BB9A9]"
                >
                  <div className="flex items-center whitespace-nowrap">
                    Next Maintenance
                  </div>
                </th>
                <th
                  scope="col"
                  className="py-3 uppercase font-normal  text-[#9BB9A9]"
                >
                  Alerts
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 1 ? "bg-[#FFFFFF00] " : "bg-[#FFFFFF0D]"
                  } `}
                >
                  <th className="px-[20px] py-2 font-medium  whitespace-nowraptext-white">
                    {item?.ID}
                  </th>
                  <td className="  py-2  text-left">{item?.Type}</td>
                  <td className="py-2 ">{item?.Location}</td>
                  <td className="py-2 ">
                    <div className="flex items-center">
                      <div className="w-[8px] h-[8px] mr-4 rounded-full bg-[#99CC33]"></div>
                      {item?.Status}
                    </div>
                  </td>
                  <td className="py-2 ">{item?.LastMaintenance}</td>
                  <td className="py-2 ">{item?.NextMaintenance}</td>
                  <td className="py-2 ">{item?.Alerts}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
