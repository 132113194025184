import React, { useState } from "react";

export default function GeneralSetting() {
  const languageArray = ["English", "Spanish", "French", "German"];
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  return (
    <div>
      <div>
        <p className="text-[#9BB9A9] text-[10px]">Language Preference</p>
        <div className="flex mt-2 gap-[4px]">
          {languageArray.map((item) => (
            <div
              onClick={() => setSelectedLanguage(item)}
              className={`p-[8px] rounded-[8px] ${
                selectedLanguage === item && "bg-[#2B383F]"
              }  border border-[#FFFFFF1C] cursor-pointer`}
            >
              {item}
            </div>
          ))}
        </div>
        <div className="mt-8">
          <p className="text-[#9BB9A9] text-[10px]">Time Zone</p>
          <select className="w-[132px] h-[42px] indent-2 custom-select  rounded-[8px] bg-[#FFFFFF0D] focus-visible:outline-none mt-2">
            <option>GMT</option>
            <option>GMT</option>
            <option>GMT</option>
            <option>GMT</option>
          </select>
        </div>
      </div>
    </div>
  );
}
