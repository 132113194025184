import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary components
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const StackedBarChart = () => {
  const labels = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
  ];
  const datasets = [
    {
      label: "Dataset 1",
      backgroundColor: "#99CC33",
      borderColor: "#99CC33",
      borderWidth: 2,
      data: [65, 59, 80, 81, 56, 55, 40, 30, 50, 50],
      barThickness: 5,
    },
    {
      label: "Dataset 2",
      backgroundColor: "#FF8B38",
      borderColor: "#FF8B38",
      borderWidth: 2,
      data: [35, 49, 70, 71, 46, 45, 30, 20, 40, 40],
      barThickness: 5,
    },
    {
      label: "Dataset 3",
      backgroundColor: "#3887FF",
      borderColor: "#3887FF",
      borderWidth: 2,
      data: [25, 39, 60, 61, 36, 35, 20, 10, 30, 30],
      barThickness: 5,
    },
    {
      label: "Dataset 4",
      backgroundColor: "#B94D4D",
      borderColor: "#B94D4D",
      borderWidth: 2,
      data: [15, 29, 50, 51, 26, 25, 10, 5, 20, 20],
      barThickness: 5,
    },
    {
      label: "Dataset 5",
      backgroundColor: "#38F3FF",
      borderColor: "#38F3FF",
      borderWidth: 2,
      data: [5, 19, 40, 41, 16, 15, 5, 2, 10, 10],
      barThickness: 5,
    },
    {
      label: "Dataset 6",
      backgroundColor: "#737ABB",
      borderColor: "#737ABB",
      borderWidth: 2,
      data: [5, 19, 40, 41, 16, 15, 5, 2, 10, 10],
      barThickness: 5,
    },
  ];

  // Calculate the top dataset for each bar
  const topDatasetIndices = labels.map((_, index) => {
    let maxIndex = 0;
    let maxValue = datasets[0].data[index];

    datasets.forEach((dataset, datasetIndex) => {
      if (dataset.data[index] > maxValue) {
        maxIndex = datasetIndex;
        maxValue = dataset.data[index];
      }
    });

    return maxIndex;
  });

  // Apply borderRadius to the top dataset
  const updatedDatasets = datasets.map((dataset, datasetIndex) => {
    const newDataset = { ...dataset, borderRadius: {} };

    labels.forEach((_, index) => {
      if (datasetIndex === topDatasetIndices[index]) {
        newDataset.borderRadius = {
          topLeft: 5,
          topRight: 5,
        };
      }
    });

    return newDataset;
  });

  const data = {
    labels,
    datasets: updatedDatasets,
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Total kgsCO2e",
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          color: "#FFFFFF0F", // Change this to your desired color
        },
        ticks: {
          color: "#9BB9A9", // Change this to your desired color
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          color: "#FFFFFF0F", // Change this to your desired color
        },
        ticks: {
          color: "#9BB9A9", // Change this to your desired color
        },
      },
    },
  };

  return <Bar data={data} options={options} height={240} />;
};

export default StackedBarChart;
