import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import GeneralSetting from "../generalSetting";
import PersonalSetting from "../personalization";
import DataControlSetting from "../dataControlSetting";
import UserManagmentSetting from "../userManagment";
import NotificationSetting from "../notification";
const SettingModal = ({ show, onClose }) => {
  const settingArray = [
    {
      title: "General",
      icon: "/setting1.svg",
      icon1: "general12.svg",
    },
    {
      title: "Personalization",
      icon: "/person.svg",
      icon1: "per1.svg",
    },
    {
      title: "Data controls",
      icon: "/data.svg",
      icon1: "data12.svg",
    },
    {
      title: "User Management",
      icon: "/user1.svg",
      icon1: "user12.svg",
    },
    {
      title: "Notification",
      icon: "/noti.svg",
      icon1: "noti12.svg",
    },
  ];
  const [selectedSetting, setSelectedSetting] = useState("General");
  if (!show) return null;
  return (
    <>
      <div
        className="fixed inset-0 bg-black cursor-pointer opacity-[90%] flex justify-center items-center z-40 w-[100%] h-[100vh]"
        onClick={onClose}
      ></div>
      <div className="fixed inset-0   z-50  h-fit max-w-[685px] m-auto rounded-[12px] bg-[#15232A]  p-[20px] ">
        <div className="flex items-center justify-between w-[100%]">
          <p className="font-semibold text-[18px]">Settings</p>
          <img
            src="/close1.svg"
            alt=""
            className="cursor-pointer"
            onClick={onClose}
          />
        </div>
        <div className="border border-[#FFFFFF14] mt-[25px]" />
        <div className="mt-[20px]">
          <div className="grid grid-cols-12 gap-[58px]">
            <div className="grid col-span-4">
              {settingArray.map((item) => (
                <div
                  onClick={() => setSelectedSetting(item?.title)}
                  className={`flex items-center h-[40px] rounded-[12px] ${
                    selectedSetting === item?.title && "bg-[#F0F4F91A]"
                  }  px-[10px] mb-1 cursor-pointer`}
                >
                  <img
                    src={
                      selectedSetting === item?.title ? item?.icon1 : item?.icon
                    }
                    alt=""
                  />
                  <p className="text-[14px] ml-[8px]">{item?.title}</p>
                </div>
              ))}
            </div>
            <div className="grid col-span-8">
              {selectedSetting === "General" && <GeneralSetting />}

              {selectedSetting === "Personalization" && <PersonalSetting />}
              {selectedSetting === "Data controls" && <DataControlSetting />}
              {selectedSetting === "User Management" && (
                <UserManagmentSetting />
              )}
              {selectedSetting === "Notification" && <NotificationSetting />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingModal;
