import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {siderbarArray} from "../../data";

export default function Sidebar({ setIsMenu, isMenu, setShow, setIsSetting }) {
  const navigate = useNavigate();
  const location = useLocation();
  const userimagepath=localStorage.getItem('user_image')

  console.log("location", location.pathname);
  return (
    <div className="relative h-[100vh] xl:block hidden ">
      <div className={`w-[72px] h-[100vh] overflow-scroll my-scrollar pl-[25px]   flex-col bg-[#243c3c] pt-[27px] pb-[39px] `}>
        <div className="">
          <img src="/logo.svg" alt="Logo" onClick={() => navigate("/")} className="cursor-pointer"/>
          <div className="mt-[39px] flex items-center">
            <img onClick={() => setIsMenu(!isMenu)} src="/hamburger.svg" alt="Toggle Menu" className="cursor-pointer"/>
          </div>
          <div className="mt-[62px]">
            {siderbarArray.map((item) => (
              <div onClick={() => navigate(item.route)} className="flex items-center cursor-pointer mt-[35px]">
                <img src={location.pathname === item?.route ? item?.icon1 : item?.icon}
                  alt="Home" className="cursor-pointer" onClick={() => navigate(item.route)}/>
                <p className={`text-[#9BB9A9] whitespace-nowrap text-[13px] ml-3 ${isMenu ? "block" : "hidden"}`}>
                  {item?.title}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-[100px]">
          <div onClick={() => setShow(true)} className={`flex items-center`}>
            <img src={userimagepath ? userimagepath : '/User.svg'} alt="User" className="cursor-pointer w-[24px] h-[24px] rounded-full mr-auto" />
            <p className={`text-[#9BB9A9] text-[13px] ${isMenu ? "block" : "hidden"}`}>
              Profile{" "}
            </p>
          </div>
          <div onClick={() => setIsSetting(true)} className="flex items-center mt-[35px] ">
            <img src="/setting.svg" alt="Settings" className=" cursor-pointer"/>
          </div>
        </div>
      </div>
      <div
        className={`${isMenu ? "w-[190px]" : "w-[0px]"}  h-[100vh] bg-[#243c3c] top-0  fixed left-[50px] pt-[93px] overflow-hidden z-30 transition-all duration-300`}>
        <p className={`text-[white] text-[13px] ml-3 ${isMenu ? "block" : "hidden"}`}>
          Menu
        </p>
        <div className="mt-[65px]">
          {siderbarArray.map((item) => (
            <div onClick={() => navigate(item.route)} className="flex items-center cursor-pointer mt-[35px]">
              <p className={`${location.pathname === item?.route ? "text-[#99CD33]" : "text-[#9BB9A9]"}  whitespace-nowrap text-[13px] ml-3 ${isMenu ? "block" : "hidden"}`}>
                {item?.title}
              </p>
            </div>
          ))}
        </div>
        <div className="mt-[104px]">
          <div onClick={() => setShow(true)} className={`flex items-center`}>
            <p className={`text-[#9BB9A9] text-[13px] ml-3 cursor-pointer ${isMenu ? "block" : "hidden"}`}>
              Profile{" "}
            </p>
          </div>
          <div onClick={() => setIsSetting(true)} className="flex items-center mt-[38px] ">
            <p className={`text-[#9BB9A9] text-[13px] ml-3 cursor-pointer ${isMenu ? "block" : "hidden"}`}>
              Setting{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
