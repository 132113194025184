import React from "react";

export default function MaintanceIdTable() {
  const tableData = [
    {
      ID: "MENT 01",
      Type: "Meter 1",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "MENT 02",
      Type: "Meter 2",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "MENT 03",
      Type: "Meter 4",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "MENT 04",
      Type: "Meter 3",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "MENT 05",
      Type: "Meter 5",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "MENT 06",
      Type: "Meter 3",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "MENT 07",
      Type: "Meter 2",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "MENT 08",
      Type: "Meter 5",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "MENT 09",
      Type: "Meter 1",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    },
    {
      ID: "MENT 10",
      Type: "Meter 4",
      Location: "Basement",
      Status: "Operational",
      LastMaintenance: "01-Jan-2024",
      NextMaintenance: "01-Jul-2024",
      Alerts: "None",
    }
  ];

  return (
    <div>
      <div
        className="bg-[#FFFFFF0D] border border-[#FFFFFF0D] rounded-lg"
        style={{ boxShadow: "0px 1px 3px 0px #000000" }}
      >
        <div className="pl-[20px] pt-[20px]">
          <p className="text-[18px] font-semibold">Maintenance History </p>
        </div>

        <div className="relative overflow-x-auto sm:rounded-lg mt-[23px] overflow-y-auto h-[250px] my-scrollar">
          <table className="w-full text-sm text-left ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th
                  scope="col"
                  className="px-[20px] font-normal py-3 text-[#9BB9A9] "
                >
                  Maintenance ID
                </th>
                <th scope="col" className="py-3">
                  <div className="flex items-center uppercase  font-normal whitespace-nowrap text-[#9BB9A9]">
                    Equipment
                  </div>
                </th>
                <th
                  scope="col"
                  align="right"
                  className="py-3 flex items-center  uppercase font-normal text-[#9BB9A9]"
                >
                  <div className="flex items-center">Location</div>
                </th>

                <th
                  scope="col"
                  className="py-3 uppercase font-normal text-[#9BB9A9]"
                >
                  <div className="flex items-center whitespace-nowrap">
                    Last Maintenance
                  </div>
                </th>
                <th
                  scope="col"
                  className="py-3 uppercase font-normal  text-[#9BB9A9]"
                >
                  <div className="flex items-center whitespace-nowrap">
                    Next Maintenance
                  </div>
                </th>
                <th
                  scope="col"
                  className="py-3 uppercase font-normal  text-center text-[#9BB9A9]"
                >
                  Assigned To
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 1 ? "bg-[#FFFFFF00] " : "bg-[#FFFFFF0D]"
                  } `}
                >
                  <th className="px-[20px]  py-2 font-medium  whitespace-nowrap text-white">
                    {item?.ID}
                  </th>
                  <td className="  py-2  ">{item?.Type}</td>
                  <td className="py-2 ">{item?.Location}</td>

                  <td className="py-2 ">{item?.LastMaintenance}</td>
                  <td className="py-2 ">{item?.NextMaintenance}</td>
                  <td className="py-2  ">{item?.Alerts}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
