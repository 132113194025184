import React, {useEffect, useState} from "react";
import Sidebar from "../../components/sidebar";
import LineChart from "../../components/chart";
import StackedBarChart from "../../components/stackedbarChart";
import {categoryUsingArray} from "../../data";
import Drawer from "../../components/drawer";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {saveenergyformonth} from "../../features/energySlice";

export default function Home({
  setIsMenu,
  isMenu,
  setShow,
  setIsSetting,
  isAIMood,
  setIsAIMood,
  total,
  sumPower,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [vueValuesForMonth, setVueValuesForMonth] = useState([])
  const energyArray = [
    '28975273665966080',
    '28975281808093184',
    '28975286083399680',
    '28975293437456384',
    '29762497653219328'
  ];

  const token = "1B195C7E6543D3DDF8832388CCD2917B7DE98EB736984E8698248B4EE3742646";
  const headers = {
    "Content-Type": "application/json",
    "token": token,
  };

  const url = 'http://13.212.150.127:13333/api/Ext_AdminData/GetData';

  const fetchData = async (id) => {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const requestData = {
      "id": id,
      "stime": thirtyDaysAgo.toISOString(), // Convert to ISO string format
      "etime": today.toISOString(), // Convert to ISO string format
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }

      const data = await response.json();
      return data;

    } catch (error) {
      console.error("Error fetching data:", error);
      return null; // Handle error gracefully
    }
  };

  const fetchDataForAll = async () => {
    try {
      const promises = energyArray.map(id => fetchData(id));
      const responses = await Promise.all(promises);
      let totalVueArray = []; // Initialize an array to store total vue values for each day

      for (let i = 0; i < 30; i++) {
        let totalVueToday = 0;

        responses.forEach(response => {
          if (response && response.Data) {
            const targetDate = new Date(); // Initialize the target date
            targetDate.setDate(targetDate.getDate() - i); // Adjust target date by subtracting 'i' days
            const targetDateString = targetDate.toISOString().split('T')[0]; // Get target date in "yyyy-mm-dd" format

            const todayData = response.Data.filter(item => {
              const itemDate = new Date(item.time).toISOString().split('T')[0];
              return itemDate === targetDateString;
            });

            todayData.forEach(item => {
              totalVueToday += item.vue;
            });
          }
        });

        totalVueArray.push(totalVueToday); // Push total vue value for today into the array
      }
      setVueValuesForMonth(totalVueArray)
      dispatch(saveenergyformonth(totalVueArray))
      return totalVueArray


    } catch (error) {
      console.error("Error fetching data for all IDs:", error);
      return 0; // Return 0 or handle error as needed
    }
  };

  // Example usage in an async function or useEffect
  useEffect(() => {
    const fetchDataAndStore = async () => {
      const totalVueArray = await fetchDataForAll();
      console.log("Total Vue value for last 30 days:", totalVueArray);
      // Store or process totalVueToday as needed
    };

    const interval = setInterval(() => {
      fetchDataAndStore();
    }, 5000);
    return () => clearInterval(interval);


  }, []);

  useEffect(()=>{
    categoryUsingArray[0].value=total.toFixed()+' Kwh'
  },[])
  console.log("categoryUsingArray",categoryUsingArray)

  return (
    <div
      className="w-[100%] flex bg-no-repeat  bg-cover"
      style={{ ...(isAIMood && { backgroundImage: "url(/Carbon.png)" }) }}
    >
      <Sidebar
        setIsMenu={setIsMenu}
        isMenu={isMenu}
        setShow={setShow}
        setIsSetting={setIsSetting}
      />

      <div className="p-[24px] h-[100vh] w-[100%] overflow-scroll my-scrollar">
        <Drawer />
        <div className="flex items-center justify-between xl:mt-0 mt-3 ">
          <div>
            <p className="font-[500] sm:text-[34px] text-[20px]">Dashboard</p>
            <p className="text-[#9BB9A9] sm:text-[13px] text-[10px] font-medium">
              No Major Risk Detected
            </p>
          </div>
          <div className="flex items-center justify-end">
            <div className="lg:flex items-center justify-center mr-4 hidden">
              <small className="mr-2">Partnered by</small>
              <img src="/tuv-rheinland-logo.png" alt='TUV' className={`h-[36px] px-2 py-1 rounded bg-[#ffffff]`}/>
            </div>
            <div className="gradient-border sm:py-[12px] py-[10px] sm:px-[16px] px-[10px] ">
              <div className="flex items-center">
                <p className="text-[#D8D8D8] font-[600] text-[13px]">
                  AI Savings
                </p>
                <label className="inline-flex items-center cursor-pointer ml-2">
                  <input type="checkbox" checked={isAIMood} onChange={(event) => setIsAIMood(event.target.checked)} className="sr-only peer focus-visible:outline-none"/>
                  <div className="relative w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:bg-[#336633]  after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gradient-to-r peer-checked:from-[#99CC33] peer-checked:to-[#00C5A2]"></div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 grid-cols-1  gap-[18px] mt-[15px]">
          <div>
            <img
              src="/Group 18.png"
              alt=""
              className="cursor-pointer"
              onClick={() => navigate("/elantra-tower")}
            />
            {/* <div
              className="py-[20px] px-[13px] w-[100%] mt-[13px] rounded-[8px] bg-[#202d35] dark:bg-[#FFFFFF0D]"
              style={{ boxShadow: "0px 1px 3px 0px #000000" }}
            >
              <div className="flex items-center">
                <img src="/warrning.svg" alt="" />
                <div className="ml-[13px]">
                  <p className="text-[#FF553E] font-bold text-[13px]">
                    Warning
                  </p>
                  <p className="text-[#B0B0B0]  text-[12px] ">
                    Thermostats is not working properly
                  </p>
                </div>
              </div>
            </div> */}
            <p className="text-[#D8D8D8] font-[700] text-[13px] mt-[16px]">
              Critical Energy Information
            </p>
            <div
              style={{
                boxShadow: "0px 1px 3px 0px #000000",
                border: "1px solid #FFFFFF0D",
              }}
              className="rounded-[8px] mt-[8px]"
            >
              <div
                style={{
                  ...(isAIMood && {
                    background:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.01) 100%)",
                  }),
                }}
                className={`grid grid-cols-12  xl:gap-7 gap-3 text-[#D8D8D8] text-[13px] ${!isAIMood && "bg-[#1c282e]"
                  }  py-[9px] px-[15px] rounded-t-[8px]`}
              >
                <div className="grid col-span-6">Item</div>
                <div className="grid col-span-3">Value</div>
                <div className="grid col-span-3">Status</div>
              </div>
              <div className="grid grid-cols-12 xl:gap-7 gap-3 text-[#D8D8D8] text-[13px] bg-[#222d34] dark:bg-[#FFFFFF0D] py-[9px] px-[15px]  ">
                <div className="grid col-span-6 text-[#9BB9A9]">
                  Current energy consumption
                </div>
                <div className="grid col-span-3">{total.toFixed()}Kwh</div>
                <div className="grid col-span-3">
                  <div className="flex items-center">
                    <div
                      className={`w-[8px] h-[8px]  rounded-full bg-[#EC6B5F]`}
                    />
                    <p className="ml-1">Little High</p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 xl:gap-7 gap-3 text-[13px] text-[#D8D8D8] bg-[#182328] dark:bg-[#FFFFFF00] py-[9px] px-[15px]  ">
                <div className="grid col-span-6">Peak consumption</div>
                <div className="grid col-span-3">482Kwh</div>
                <div className="grid col-span-3">
                  <div className="flex items-center">
                    <div
                      className={`w-[8px] h-[8px]  rounded-full bg-[#99CC33]`}
                    />
                    <p className="ml-1">Normal</p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 xl:gap-7 gap-3 text-[13px] text-[#D8D8D8] bg-[#222d34] dark:bg-[#FFFFFF0D] rounded-b-[8px] py-[9px] px-[15px]  ">
                <div className="grid col-span-6">Energy cost</div>
                <div className="grid col-span-3">3 USD/Kw</div>
                <div className="grid col-span-3">
                  <div className="flex items-center">
                    <div
                      className={`w-[8px] h-[8px]  rounded-full bg-[#99CC33]`}
                    />
                    <p className="ml-1">Normal</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className=" p-[15px] rounded-[8px]  mt-[8px] bg-[#FFFFFF0D] line-chart"
              style={{
                boxShadow: "0px 1px 3px 0px #000000",
                border: "1px solid #FFFFFF0D",
              }}
            >
              <LineChart/>
            </div>
          </div>
          <div
            className="rounded-[8px] bg-[#30303033] dark:bg-[#30303033] border border-[#FFFFFF0D] pt-[28px] "
            style={{ boxShadow: "0px 1px 3px 0px #000000" }}
          >
            <img
              src="/giphy-1-unscreen.gif"
              alt=""
              className="m-auto h-[140px]"
            />
            <p className="text-[#99CC33] text-[18px] font-[600] text-center mt-[34px]">
              Uses are Under Control
            </p>
            <p className="text-[12px] text-center">
              <span className="font-[600] text-[#B0B0B0]">Total</span> 10200
              kgCO2e
            </p>
            <div className="mt-[14px] px-[14px] stack-chart ">
              <StackedBarChart />
            </div>
            <div
              style={{
                ...(isAIMood && {
                  background:
                    "linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.01) 100%)",
                }),
              }}
              className={` ${!isAIMood && "bg-[#1d262b]"
                } mt-[23px] py-[9px] px-[15px] flex justify-between items-center rounded-t-[8px] text-[13px] font-medium`}
            >
              <p className="text-[#D8D8D8]">Category</p>
              <p className="text-[#D8D8D8]">Carbon Emissions (kgC02e)</p>
            </div>
            {categoryUsingArray.map((item, index) => (
              <div
                key={index}
                className={`${index % 2 === 1
                  ? "bg-[#161f23] dark:bg-[#FFFFFF0D]"
                  : "bg-[#212b31] dark:bg-[#FFFFFF00]"
                  }   py-[9px] px-[15px] flex justify-between items-center  text-[13px] `}
              >
                <div className="flex items-center ">
                  <div
                    style={{ background: item?.color }}
                    className={`w-[8px] h-[8px]  rounded-full`}
                  />
                  <p className="text-[#9BB9A9] ml-1">{item?.title}</p>
                </div>
                <div className="flex items-center">
                  <p className="text-[#D8D8D8]">{item?.value}</p>
                  {isAIMood && (
                    <p className="text-[#99CC33] ml-1">{item?.ai}</p>
                  )}
                  {isAIMood && (
                    <img src="/percent.svg" alt="" className="ml-[2px]" />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div>
            <div
              onClick={() => navigate("/equipment-monitoring")}
              className="h-fit w-[100%] relative cursor-pointer "
            >
              <img src="/Frame 65.png" alt="" className="w-[100%]" />
              <div className="w-[100%] h-[100%] p-[10px] absolute top-0">
                <div className="flex justify-between flex-col  h-[100%] ">
                  <div className="flex justify-between">
                    <div>
                      <p className="text-[#D8D8D8] text-[13px] font-[600]">
                        8th Floor Apartment
                      </p>
                      <p className="text-[12px] text-[#B0B0B0] ">876Sqft</p>
                    </div>
                    <img src="/Mask group (2).svg" alt="" />
                  </div>
                  <div className="flex justify-between items-end">
                    <div className="flex items-center">
                      <img src="/Vector (8).svg" alt="" />
                      <p className="text-[12px] font-medium ml-[7px]  ">
                        No Risk Detected
                      </p>
                    </div>
                    <div className="flex flex-col gap-[5px]">
                      <div className="flex items-center">
                        <p className="text-[#8A8D9C] font-[600] text-[13px]">
                          X
                        </p>
                        <div className="w-[32px] h-[32px] ml-2 rounded-[8px] bg-[#FFFFFF0D] border border-[#FFFFFF08] flex items-center justify-center text-[13px] ">
                          58
                        </div>
                      </div>
                      <div className="flex items-center">
                        <p className="text-[#8A8D9C] font-[600] text-[13px]">
                          Y
                        </p>
                        <div className="w-[32px] h-[32px] ml-2 rounded-[8px] bg-[#FFFFFF0D] border border-[#FFFFFF08] flex items-center justify-center text-[13px] ">
                          128
                        </div>
                      </div>
                      <div className="flex items-center">
                        <p className="text-[#8A8D9C] font-[600] text-[13px]">
                          Z
                        </p>
                        <div className="w-[32px] h-[32px] ml-2 rounded-[8px] bg-[#FFFFFF0D] border border-[#FFFFFF08] flex items-center justify-center text-[13px] ">
                          32
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bg-[#30303033] dark:bg-[#FFFFFF00] border border-[#FFFFFF0D] mt-4 rounded-[8px]"
              style={{ boxShadow: "0px 1px 3px 0px #000000" }}
            >
              <div className="p-4">
                <div className="flex items-center">
                  <img src="/Vector (9).svg" alt="" />
                  <div className="ml-2">
                    <p className="text-[15px] text-[#D8D8D8] font-[700]">
                      Floor View
                    </p>
                    <p className="text-[#9BB9A9] text-[12px] mt-[2px]">
                      No Risk Detected
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  ...(isAIMood && {
                    background:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.01) 100%)",
                  }),
                }}
                className={`text-[#D8D8D8] ${!isAIMood && "bg-[#151f25]"
                  }  py-[9px] text-[13px] px-[15px] flex justify-between items-center rounded-t-[8px]`}
              >
                <div className="grid col-span-3 text-[#9BB9A9]">Status</div>
                <div className="grid col-span-6">Operational</div>
              </div>
              <div className="flex justify-between gap-7 text-[#D8D8D8] text-[13px] dark:bg-[#FFFFFF0D] bg-[#222d34] py-[9px] px-[15px]  ">
                <div className=" text-[#9BB9A9]">Temperature (°C)</div>
                <div className="flex items-center">
                  20
                  {isAIMood && <p className="text-[#99CC33] ml-1">5%</p>}
                  {isAIMood && (
                    <img src="/percent.svg" alt="" className="ml-[2px]" />
                  )}
                </div>
              </div>
              <div className="flex justify-between gap-7 text-[#D8D8D8] text-[13px] bg-[#182328] dark:bg-[#FFFFFF00] py-[9px] px-[15px]  ">
                <div className=" text-[#9BB9A9]">Power Consumption (kW)</div>
                <div className="flex items-center">
                  {sumPower}
                  {isAIMood && <p className="text-[#99CC33] ml-1">5%</p>}
                  {isAIMood && (
                    <img src="/percent.svg" alt="" className="ml-[2px]" />
                  )}
                </div>
              </div>
              <div className="flex justify-between gap-7 text-[#D8D8D8] text-[13px] bg-[#222d34] dark:bg-[#FFFFFF0D] py-[9px] px-[15px] rounded-b-[8px]  ">
                <div className=" text-[#9BB9A9]">Last Maintenance</div>
                <div className="">3 USD/Kw</div>
              </div>
            </div>
            {/* <div
              className="bg-[#30303033] dark:bg-[#FFFFFF00] border border-[#FFFFFF0D] mt-4 rounded-[8px]"
              style={{ boxShadow: "0px 1px 3px 0px #000000" }}
            >
              <div className="p-4">
                <div className="flex items-center">
                  <img src="/cooling.svg" alt="" />
                  <div className="ml-2">
                    <p className="text-[15px] text-[#D8D8D8] font-[700]">
                      Cooling Tower
                    </p>
                    <p className="text-[#9BB9A9] text-[12px] mt-[2px]">
                      No Risk Detected
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  ...(isAIMood && {
                    background:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.01) 100%)",
                  }),
                }}
                className={`text-[#D8D8D8] ${!isAIMood && "bg-[#151f25]"
                  }   py-[9px] text-[13px] px-[15px] flex justify-between items-center rounded-t-[8px]`}
              >
                <div className="grid col-span-3 text-[#9BB9A9]">Status</div>
                <div className="grid col-span-6">Operational</div>
              </div>
              <div className="flex justify-between gap-7 text-[#D8D8D8] text-[13px] dark:bg-[#FFFFFF0D] bg-[#222d34] py-[9px] px-[15px]  ">
                <div className=" text-[#9BB9A9]">Temperature (°C)</div>
                <div className="flex items-center">
                  20
                  {isAIMood && <p className="text-[#99CC33] ml-1">5%</p>}
                  {isAIMood && (
                    <img src="/percent.svg" alt="" className="ml-[2px]" />
                  )}
                </div>
              </div>
              <div className="flex justify-between gap-7 text-[#D8D8D8] text-[13px] dark:bg-[#FFFFFF00] bg-[#182328] py-[9px] px-[15px]  ">
                <div className=" text-[#9BB9A9]">Power Consumption (kW)</div>
                <div className="flex items-center">
                  {sumPower}
                  {isAIMood && <p className="text-[#99CC33] ml-1">5%</p>}
                  {isAIMood && (
                    <img src="/percent.svg" alt="" className="ml-[2px]" />
                  )}
                </div>
              </div>
              <div className="flex justify-between gap-7 text-[#D8D8D8] text-[13px] dark:bg-[#FFFFFF0D] bg-[#222d34] py-[9px] px-[15px] rounded-b-[8px]  ">
                <div className=" text-[#9BB9A9]">Last Maintenance</div>
                <div className="">3 USD/Kw</div>
              </div>
            </div> */}
          </div>
        </div>

        {/*<div>*/}
        {/*  <div className="flex items-center justify-end">*/}
        {/*    <div className="flex items-end justify-center pt-4">*/}
        {/*      <small className={`mr-2`}>Developed by</small>*/}
        {/*      <a href="https://mettlesoft.com.au" target="_blank">*/}
        {/*        <img src="/mettlesoft.png" alt='Mettlesoft' className={` h-[30px]`}/>*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
