import React from "react";

const Stats = () => {
  const totalEquipment = 289;
  const operational = 268;
  const underMaintenance = 12;
  const failed = 9;

  return (
    <div
      className="  bg-[#FFFFFF0D] border border-[#FFFFFF0D]  p-4 rounded-lg"
      style={{ boxShadow: "0px 1px 3px 0px #000000" }}
    >
      <div className="grid grid-cols-4 ">
        <div className="">
          <div className="  sm:pr-4 pr-1 ">
            <div className="sm:text-[12px] text-[8px] text-[#9BB9A9]">
              Total Equipment
            </div>
            <div className="text-[13px] font-[600]">289</div>
          </div>
        </div>
        <div className="">
          <div className=" border-l border-[#313f46] sm:pl-6 pl-2">
            <div className="text-[12px] text-[#9BB9A9]">Operational</div>
            <div className="text-[13px] font-[600] text-[#99CC33]">268</div>
          </div>
        </div>
        <div className="">
          <div className=" border-l border-[#313f46] sm:pl-6 pl-2">
            <div className="sm:text-[12px] text-[8px] text-[#9BB9A9]">
              Under Maintenance
            </div>
            <div className="text-[13px] font-[600] text-[#FF8B38]">12</div>
          </div>
        </div>
        <div className="">
          <div className=" border-l border-[#313f46] sm:pl-6 pl-2">
            <div className="sm:text-[12px] text-[8px] text-[#9BB9A9]">
              Failed
            </div>
            <div className="text-[13px] font-[600] text-[#FF553E]">9</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
