import React from "react";
import Sidebar from "../../components/sidebar";
import Stats from "../../components/stats";
import EquipmentListTable from "../../components/equipmentListTable";
import MaintanceIdTable from "../../components/maintanceIdTable";
import Drawer from "../../components/drawer";
import { useNavigate } from "react-router-dom";

export default function EquipmentMonitoring1({
  setIsMenu,
  isMenu,
  setShow,
  setIsSetting,
  isAIMood,
  setIsAIMood,
}) {
  const navigate = useNavigate();
  return (
    <div
      className="w-[100%] flex bg-no-repeat  bg-cover"
      style={{ ...(isAIMood && { backgroundImage: "url(/Carbon.png)" }) }}
    >
      <Sidebar
        setIsMenu={setIsMenu}
        isMenu={isMenu}
        setShow={setShow}
        setIsSetting={setIsSetting}
      />
      <div className="md:p-[24px] p-[12px] h-[100vh] w-[100%] overflow-scroll my-scrollar">
        <Drawer />
        <div className="sm:flex items-center justify-between xl:mt-0 mt-3 ">
          <div>
            <p className="font-[500] sm:text-[34px] text-[16px]">
              Equipment Monitoring
            </p>
            <p className="text-[#9BB9A9] sm:text-[13px] text-[10px] font-medium">
              Overview and status of all HVAC equipment
            </p>
          </div>
          <div className="flex items-center justify-end">
            <div className="lg:flex items-center justify-center mr-4 hidden">
              <small className="mr-2">Partnered by</small>
                            <img src="/tuv-rheinland-logo.png" alt='TUV' className={`h-[36px] px-2 py-1 rounded bg-[#ffffff]`}/>
            </div>
          <div class="gradient-border py-[12px] px-[16px] sm:mt-0 mt-3 ">
            <div className="flex items-center">
              <p className="text-[#D8D8D8] font-[600] text-[13px]">
                AI Savings
              </p>
              <label class="inline-flex items-center cursor-pointer ml-2">
                <input
                  type="checkbox"
                  checked={isAIMood}
                  onChange={(event) => setIsAIMood(event.target.checked)}
                  class="sr-only peer focus-visible:outline-none"
                />
                <div class="relative w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:bg-[#336633]  after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gradient-to-r peer-checked:from-[#99CC33] peer-checked:to-[#00C5A2]"></div>
              </label>
            </div>
          </div>
          </div>
        </div>
        <div className="grid grid-cols-12 mt-[30px] gap-4 ">
          <div className="grid lg:col-span-8 col-span-12 ">
            <Stats />
            <div className="mt-[16px] my-scrollar overflow-scroll">
              <div className="min-w-[700px] ">
                <EquipmentListTable />
              </div>
            </div>
            <div className="mt-[16px] my-scrollar overflow-scroll">
              <div className="min-w-[700px] ">
                <MaintanceIdTable />
              </div>
            </div>
          </div>
          <div className="grid lg:col-span-4 col-span-12 ">
            <div>
              <div
                onClick={() => navigate("/equipment-monitoring")}
                className="h-fit w-[100%] relative cursor-pointer "
              >
                <img
                  src="/Frame 65.png"
                  alt=""
                  className="w-[100%] cursor-pointer"
                />
                <div className="w-[100%] h-[100%] p-[10px] absolute top-0">
                  <div className="flex justify-between flex-col  h-[100%] ">
                    <div className="flex justify-between">
                      <div>
                        <p className="text-[#D8D8D8] text-[13px] font-[600]">
                          8th Floor Apartment
                        </p>
                        <p className="text-[12px] text-[#B0B0B0] ">876Sqft</p>
                      </div>
                      <img src="/Mask group (2).svg" alt="" />
                    </div>
                    <div className="flex justify-between items-end">
                      <div className="flex items-center">
                        <img src="/Vector (8).svg" alt="" />
                        <p className="text-[12px] font-medium ml-[7px]  ">
                          No Risk Detected
                        </p>
                      </div>
                      <div className="flex flex-col gap-[5px]">
                        <div className="flex items-center">
                          <p className="text-[#8A8D9C] font-[600] text-[13px]">
                            X
                          </p>
                          <div className="w-[32px] h-[32px] ml-2 rounded-[8px] bg-[#FFFFFF0D] border border-[#FFFFFF08] flex items-center justify-center text-[13px] ">
                            58
                          </div>
                        </div>
                        <div className="flex items-center">
                          <p className="text-[#8A8D9C] font-[600] text-[13px]">
                            Y
                          </p>
                          <div className="w-[32px] h-[32px] ml-2 rounded-[8px] bg-[#FFFFFF0D] border border-[#FFFFFF08] flex items-center justify-center text-[13px] ">
                            128
                          </div>
                        </div>
                        <div className="flex items-center">
                          <p className="text-[#8A8D9C] font-[600] text-[13px]">
                            Z
                          </p>
                          <div className="w-[32px] h-[32px] ml-2 rounded-[8px] bg-[#FFFFFF0D] border border-[#FFFFFF08] flex items-center justify-center text-[13px] ">
                            32
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="border border-[#FFFFFF0D] bg-[#FFFFFF0D] rounded-[8px] mt-4 px-4 pt-[23px] h-[325px] "
                style={{ boxShadow: "0px 1px 3px 0px #000000" }}
              >
                <p className="text-[18px] font-semibold">
                  Alerts and Notifications
                </p>
                <div className="bg-[#FFFFFF0D] py-4 px-3 rounded-[8px] flex items-center mt-[19px]">
                  <img src="/thermo.svg" alt="" />
                  <div className="ml-2">
                    <p className="text-[13px] font-semibold">
                      Thermostats is working properly
                    </p>
                    <div className="flex gap-x-3 items-center ">
                      <p className="text-[#9BB9A9] text-[12px] ">HVAC-09</p>
                      <div className="w-[5px] h-[5px] bg-[#9BB9A9] rounded-full"></div>
                      <p className="text-[#9BB9A9] text-[12px]">Basement</p>
                    </div>
                  </div>
                </div>
                <div className="bg-[#FFFFFF0D] py-4 px-3 rounded-[8px] flex items-center mt-[19px]">
                  <img src="/ac.svg" alt="" />
                  <div className="ml-2">
                    <p className="text-[13px] font-semibold">
                      AC Unit is working properly
                    </p>
                    <div className="flex items-center gap-x-3 ">
                      <p className="text-[#9BB9A9] text-[12px] ">HVAC-04</p>
                      <div className="w-[5px] h-[5px] bg-[#9BB9A9] rounded-full"></div>
                      <p className="text-[#9BB9A9] text-[12px]">3rd Floor </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<div>*/}
        {/*  <div className="flex items-center justify-end">*/}
        {/*    <div className="flex items-end justify-center pt-4">*/}
        {/*      <small className={`mr-2`}>Developed by</small>*/}
        {/*      <a href="https://mettlesoft.com.au" target="_blank">*/}
        {/*        <img src="/mettlesoft.png" alt='Mettlesoft' className={` h-[30px]`}/>*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
