import React, { useState } from "react";

export default function DataControlSetting() {
  return (
    <div>
      <div>
        <div>
          <p className="text-[#9BB9A9] text-[10px]">DATA REFRESH RATE</p>
          <select className="w-[240px] indent-2 h-[42px] custom-select  rounded-[8px] bg-[#FFFFFF0D] focus-visible:outline-none mt-2">
            <option>Every 5 minutes</option>
            <option>Every 10 minutes</option>
            <option>Every 15 minutes</option>
            <option>Every 20 minutes</option>
          </select>
        </div>
        <div className="mt-8">
          <p className="text-[#9BB9A9] text-[10px]">DEFAULT EXPORT FORMATE</p>
          <select className="w-[240px] indent-2 custom-select h-[42px]  rounded-[8px] bg-[#FFFFFF0D] focus-visible:outline-none mt-2">
            <option>PDF</option>
            <option>DOC</option>
            <option>EXCL</option>
          </select>
        </div>
      </div>
    </div>
  );
}
