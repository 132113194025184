import React, { useState } from "react";

export default function UserManagmentSetting() {
  return (
    <div>
      <div>
        <div>
          <p className="text-[#9BB9A9] text-[10px]">USER ROLES</p>
          <select className="w-full indent-2 h-[42px] custom-select  rounded-[8px] bg-[#FFFFFF0D] focus-visible:outline-none mt-2">
            <option>Manager</option>
            <option>Admin</option>
            <option>User</option>
          </select>
        </div>
        <div className="mt-8">
          <p className="text-[#9BB9A9] text-[10px]">INVITE NEW USER</p>
          <div className="flex items-center gap-3">
            <input
              type="text"
              placeholder="Enter Email"
              className="w-[70%] indent-2 h-[42px]  rounded-[8px] bg-[#FFFFFF0D] focus-visible:outline-none mt-2"
            />
            <select className="w-[30%] indent-2 h-[42px] custom-select  rounded-[8px] bg-[#FFFFFF0D] focus-visible:outline-none mt-2">
              <option>ROLE</option>
              <option>User</option>
              <option>Manager</option>
              <option>Admin</option>
            </select>
          </div>
        </div>
        <button className="h-[42px] rounded-[8px] bg-[#99CC33] text-black text-[13px] font-semibold w-[100px] mt-[10px]">
          Send Invite
        </button>
      </div>
    </div>
  );
}
